import React from "react";

export default function Businesses() {
    return (
        <section className="business_section">
            <div className="container">
                <div className="col-12">
                    <h3>TOP-PERFORMING BUSINESSES USE HUMANELY</h3>
                    <h1>We are helping businesses like <br /> yours save <span>productive</span> hours</h1>
                    <p className="subheading">Unlock the full potential of your team and drive efficiency and productivity with our innovative solutions that make work simple and businesses better.</p>
                    <a href="/" className="btn">Book a Demo</a>
                    <a href="/platform" className="btn btn2">Learn More</a>
                </div>
            </div>

            <div className="three-highlights">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-md-6 col-sm-12 col-xs-12 col-12">
                            <div className="highlights">
                                <div 
                                    className="highlight-image"
                                    style={{
                                        backgroundImage: `url(${`https://smarthr.dreamstechnologies.com/assets/img/inner-pages/inner-page-31.webp`})`, 
                                        backgroundPosition:'center'
                                    }}
                                >
                                    <div className="full-cover">
                                        <div className="inner-highlight">
                                            <p>Streamline and automate tasks and workflows. Assign and track tasks and completion ratings.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="highlights">
                                <div 
                                    className="highlight-image"
                                    style={{
                                        backgroundImage: `url(${`https://smarthr.dreamstechnologies.com/assets/img/inner-pages/inner-page-30.webp`})`, 
                                    }}
                                >
                                    <div className="full-cover">
                                        <div className="inner-highlight">
                                            <p>Manage employee hours and track productivity with our robust time tracking software.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 col-xs-12 col-12">
                            <div className="highlights">
                                <div 
                                    className="highlight-image"
                                    style={{
                                        backgroundImage: `url(${`https://seamlesshr.com/wp-content/uploads/Reporting-and-Analytics.webp`})`, 
                                    }}
                                >
                                    <div className="full-cover">
                                        <div className="inner-highlight">
                                            <p>Save time, streamline requests, and enjoy an easier way to manage all requisitions.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="highlights">
                                <div 
                                    className="highlight-image"
                                    style={{
                                        backgroundImage: `url(${`https://seamlesshr.com/wp-content/uploads/Online-Testing-1.webp`})`, 
                                    }}
                                >
                                    <div className="full-cover">
                                        <div className="inner-highlight">
                                            <p>Fill your pipeline fast. Create and sync job openings with your organogram and share them online with unique URLs so you know where quality applications are coming from.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </section> 
    );
}
