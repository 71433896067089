import React, { useEffect, useState } from "react";

import isLoggedIn from "../../utils/isLoggedIn";
import instance from "../../utils/api";

import logo from "../../assets/logodark.png";
// import { alertSuccess, alertError } from "../../utils/alerts";


export default function Login(props) {
    const [loading, setLoading] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);

    useEffect(() => {
        isLoggedIn() && props.history.push("/dashboard");
    }, [props]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            email: e.target.email.value,
            password: e.target.password.value,
        };
        
        if(data.email && data.password){
            try{
                setLoading(true);
                const res = await instance.post("/user/login", data);

                if(res.data.success){
                    localStorage.setItem("kloka:token:data", res.data.token);
                    localStorage.setItem("kloka:role", res.data.user.role); 
                    localStorage.setItem("kloka:type", res.data.user.type);  
                    localStorage.setItem("kloka:categories", res.data.user.categories);            
                    // alertSuccess("Login Successful.");
                    setLoading(false);
                    props.history.push("/dashboard");
                    window.location.reload();
                }
            } catch (error){
                setLoading(false);
                // alertError(error?.response?.data?.error);
            }
        }
    };

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    return (
        <>  

            <section className="quotes mt-4" style={{padding:20, marginTop: 20, minHeight:'100vh'}}>
                <div className="row">
                    <div className="text-center">
                        <img src={logo} className="" alt="logo" width={150} height={45} style={{marginBottom: 20}} />
                    </div>
                    
                    <div className="col-6" style={{backgroundColor:'red'}}>

                    </div>
                    
                    <div className="col-md-6 account">

                        <div className="">
                            <h2 className="font-weight-bold" style={{marginBottom:0}}>Login</h2>
                            <form onSubmit={handleSubmit}>
                                <p className="font-weight-bold text-danger"> </p>
                                <div className="row">
                                    <div className="form-group col-md-12">
                                        <label>Email</label>
                                        <input type="email" name="email" required placeholder="Email" id="email" className="form-control" />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label>Password</label>
                                        <div className="pass-wrapper">
                                            <input 
                                                type={passwordShown ? "text" : "password"} 
                                                name="pass" 
                                                className="form-control" 
                                                id="password" 
                                                placeholder="Your Password" 
                                                required 
                                            />
                                            <span onClick={togglePasswordVisiblity}>
                                                {passwordShown ? (
                                                    <i className="fas fa-eye-slash" aria-hidden="true"></i>
                                                ) : (
                                                    <i className="fas fa-eye" aria-hidden="true"></i>
                                                )}
                                            </span>
                                        </div> 
                                    </div>
                                    <div className="col-md-12">
                                        <button type="submit"  className="btn btn-primary" 
                                            disabled={loading ? true : false}>
                                            {!loading ? "Login" : <i className="fa fa-spinner fa-spin"></i>}
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <div className="form_links">
                                <p>Forgot your Password? <a href="/forgot-password">Reset it</a></p>
                            </div>
                        </div>

                    </div>
                

                    <p 
                        className="text-center mt-4" 
                        style={{fontSize:10, marginBottom:3, color:'#000'}}>
                        © 2024. <b>Humanely</b>
                        <br /> 
                        <span style={{fontSize:10, marginBottom:3, color:'#000'}}>Powered by 
                            <a href="https://hub9technologies.com.ng" target="_blank"  rel="noreferrer">Hub9 Technologies</a></span>
                    </p>
                    
                </div>
            </section>

        </>
    );
}
