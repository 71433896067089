import React from "react";
import image_3 from "../../assets/image_3.jpeg";
import onboarding from "../../assets/onboarding.png";
import information from "../../assets/information.png";
import requisition from "../../assets/requisition.png";
import reports from "../../assets/reports.png";
import leave from "../../assets/leave.png";
import offboarding from "../../assets/offboarding.png";

function Processes() {
    return (
        <>
            <section className="process_section">
                <div className="container">
                    <div className="col-12">
                        <h3>PUTTING YOUR PEOPLE FIRST</h3>
                        <h1>Everything you need to manage your <span>people</span> and processes</h1>
                        <a href="/" className="btn">Talk to a Specialist</a>
                    </div>
                </div>
                <div className="five-highlights" style={{marginBottom: 40}}>
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-md-12 col-sm-12 col-xs-12 col-12">
                                <div className="highlights">
                                    <div 
                                        className="highlight-image highlight-center-image"
                                        style={{
                                            backgroundImage: `url(${image_3})`, 
                                        }}>
                                    </div>
                                </div>
                                
                            </div>
                        </div> 
                    </div>
                </div> 
            
                <div className="container">
                    <div className="row g-4">
                        <div className="col-md-4 col-sm-6 col-12">
                            <div className="process_card">
                                <img src={onboarding} alt="onboarding" />
                                <h4>Onboarding</h4>
                                <p>Create self-guided onboarding workflow for new employees to get them started in record time.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12">
                            <div className="process_card">
                                <img src={information} alt="information" />
                                <h4>Information Management</h4>
                                <p>Manage employee data and documents, assign assets and resources, all in one place.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12">
                            <div className="process_card">
                                <img src={requisition} alt="requisition" />
                                <h4>Requests Management</h4>
                                <p>Manage all requisitions using smart policies that take care of all the diverse scenarios.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12">
                            <div className="process_card">
                                <img src={leave} alt="requisition" />
                                <h4>Leave Management</h4>
                                <p>Manage all requisitions using smart policies that take care of all the diverse scenarios.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12">
                            <div className="process_card">
                                <img src={reports} alt="reports" />
                                <h4>Reports & Analytics</h4>
                                <p>Generate any type of report with data from your organization with our custom report builder.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12">
                            <div className="process_card">
                                <img src={offboarding} alt="reports" />
                                <h4>Exit & Offboarding</h4>
                                <p> Map out the offboarding process from start to finish and ensure everyone is in the loop.</p>
                            </div>
                        </div>
                    </div>
                </div>                                
            </section>            
        </>
    );
}

export default Processes;