import React from "react";
import Header from "../inc/Header";
import Footer from "../inc/Footer";

import HomeHero from "./HomeHero";
import Solutions from "./Solutions";
import Processes from "./Processes";
import Businesses from "./Businesses";


export default function Home() {
    return (
        <>
            <Header />
            
            <HomeHero />
            <Solutions />
            <Processes />
            <Businesses />

            <Footer />
        </>
    );
}
