export default function isLoggedIn() {
  const token = localStorage.getItem("HUMANELY:token:data");
  const role = localStorage.getItem("HUMANELY:role"); 
  const type = localStorage.getItem("HUMANELY:type"); 
  const categories = localStorage.getItem("HUMANELY:categories"); 
  
  if (token && role && type && categories) {
    return true;
  } else {
    return false;
  }
}
