import axios from "axios";

const TOKEN = localStorage.getItem("kloka:token:data");

const instance = axios.create({
  baseURL: "http://localhost:9000/api",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${TOKEN}`,
  },
});

export default instance;
