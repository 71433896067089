import React from "react";

function Solutions() {
    return (
        <>
            <section className="listed_companies">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-12">
                            <div className="solutions">
                                <a href="/">Core HR</a>
                                <a href="/">Performance</a>
                                <a href="/">Time and Attendance</a>
                                <a href="/">Recruitment</a>
                                <a href="/">Payroll</a>
                                <a href="/">Requisitions</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>            
        </>
    );
}

export default Solutions;