import { BrowserRouter as Router, Route } from "react-router-dom";

// load components
import Home from "./pages/Home";
import About from "./pages/About";
import Demo from "./pages/Demo/index";
import Customers from "./pages/Customers/index";


import "./App.css";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-multi-carousel/lib/styles.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function App() {
  return (
    <Router>
      <Route exact path={"/"} component={Home}></Route>
      <Route exact path={"/about"} component={About}></Route>
      <Route exact path={"/customers"} component={Customers}></Route>
      <Route exact path={"/demo"} component={Demo}></Route>

      {/* <Route exact path={"*"} component={Home}></Route> */}
      
    </Router>
  );
}

export default App;
