import React from "react";
import home_hero from "../../assets/home_hero.jpg";

function HomeHero() {
    return (
        <>
            <section className="page_top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <h1>Human-centric HR software for organizations</h1>
                            <p>Organize and automate all processes within your organization using a human-centric software, built for top performing organizations.</p>
                            <a href="/" className="btn">Schedule a Free Demo</a>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div className="highlights">
                                <div 
                                    className="highlight-image"
                                    style={{
                                        backgroundImage: `url(${home_hero})`, 
                                    }}>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>            
        </>
    );
}

export default  HomeHero;