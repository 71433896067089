import React from "react";
import Header from "../inc/Header";
import Footer from "../inc/Footer";

import AboutHero from "./AboutHero";
import FiveImages from "./FiveImages";
import OurStory from "./OurStory";
import OurNumbers from "./OurNumbers";
import OurTeam from "./OurTeam";
import OurValues from "./OurValues";

export default function AboutUs() {
    return (
        <>
            <Header />
            
            <AboutHero />
            <FiveImages />
            <OurStory />
            <OurNumbers />
            <OurTeam />
            <OurValues />

            <Footer />
        </>
    );
}
