import React from "react";
// import logo from "../../assets/logo.png";

function Header() {
    return (
        <>
            <header id="header" className="header d-flex align-items-center fixed-top">
                <div className="container-fluid container-xl d-flex align-items-center justify-content-between">

                    <a href="/" className="logo d-flex align-items-center">
                        <h1 style={{fontWeight:'bold', margin:10}}>humanely</h1>
                        {/* <img className="logo" src={logo} alt="logo" style={{height: 25}}  /> */}
                    </a>

                    <nav id="navbar" className="navbar ms-auto">
                        <ul>
                            <li><a href="/"><span>Platform</span></a></li>
                            <li><a href="/customers"><span>Customers</span></a></li>
                            <li><a href="/"><span>Pricing</span></a></li>
                            <li><a href="/"><span>Resources</span></a></li>      
                            <li><a href="/about"><span>Company</span></a></li>
                            <li><a href="/" className="btn"><span>Request a Demo</span></a></li>
                        </ul>
                    </nav>

                    <div className="position-relative">
                        <i className="bi bi-list mobile-nav-toggle"></i>
                    </div>

                </div>
            </header>

        </>
    );
}

export default Header;
