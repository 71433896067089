import React from "react";
import Header from "../inc/Header";
import Footer from "../inc/Footer";

import { Carousel } from "react-bootstrap";

import team_member_1 from "../../assets/team_member_1.png";


export default function Home() {
    return (
        <>
            <Header />
            
            <div className="tourism-travel-hero" style={{marginTop: 90}}>
                <div className="slider-home-banner slide">
                    <Carousel
                        interval={1000}
                        indicators={false}
                        indicatorLabels={false}
                        nextLabel={false}
                        prevLabel={false}
                        touch={false}
                        fade
                    >
                        <Carousel.Item>
                            <div 
                                className="tourism-travel-hero-image"
                                style={{
                                    borderRadius: 0, 
                                    backgroundImage: `url(${`https://cdn.prod.website-files.com/662a73cd57ea3ca46cddb290/666f51b6c1e7e52083683e86_customer-min-p-2000.png`})`, 
                                    backgroundSize: 'cover', 
                                    backgroundPosition: 'top',
                                    margin: '0'
                                }}>
                            </div>
                            <Carousel.Caption>
                                <div className="hightitle">
                                    <h5>We know what it feels like, we deeply care and we will support you to win no matter what.</h5>
                                    <p>-- The Humanely Team.</p>
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                </div>
            </div>

            <section className="process_section">
                <div className="container">
                    <div className="col-12">
                        <h3>GROWING BUSINESSES USE HUMANELY</h3>
                        <h1>Everything you need to manage your <span>people</span> and processes</h1>
                        <a href="/" className="btn">Talk to a Specialist</a>
                    </div>
                </div>
                <div className="five-highlights" style={{marginBottom: 40}}>
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-md-12 col-sm-12 col-xs-12 col-12">
                                <div className="highlights">
                                    <div 
                                        className="highlight-image highlight-center-image"
                                        style={{
                                            backgroundImage: `url(${team_member_1})`, 
                                        }}>
                                    </div>
                                </div>
                                
                            </div>
                        </div> 
                    </div>
                </div> 
            </section>

            <Footer />
        </>
    );
}
